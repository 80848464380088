'use strict'

angular
  .module 'lpaIncidentTracking'
  .config ($stateProvider) ->
    $stateProvider
      .state 'incidentTracking',
        url: '/incident-tracking/{incident_id}',
        templateUrl: 'lpa/lpa-incident-tracking/views/lpa-incident-tracking.tpl.html'
        controller: 'IncidentTrackingCtrl'
        controllerAs: 'incidentTracking'
        onEnter: [
          'MundoSocket'
          (MundoSocket) ->
            MundoSocket.toggleUnitStatusConsumer true
            MundoSocket.toggleUnitEventConsumer true
            MundoSocket.toggleTenantEventConsumer true
        ]
        onExit: [
          'MundoSocket'
          (MundoSocket) ->
            MundoSocket.toggleUnitStatusConsumer false
            MundoSocket.toggleUnitEventConsumer false
            MundoSocket.toggleTenantEventConsumer false
        ]
        data:
          navigation: false
